const _useScrollTop = () => useState<boolean>('scrollTop',()=>false)

export default function(show:boolean){
    const showScrollTop = _useScrollTop();

    onBeforeUnmount(()=>{
        showScrollTop.value = false
    })

    onMounted(()=>{
        showScrollTop.value = show
    })

    return{
        showScrollTop
    }

}